import { User } from "@/types";
import { Link } from "@inertiajs/react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "./ui/DropdownMenu";
import { ChevronDown, ChevronUp, LogOut, User2 } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type StudentProfileProps = {
    user: User;
    role: "admin" | "public";
};
// Assume you have a flag to indicate unread notifications
const hasUnreadNotifications = true;

const StudentProfile = ({ user, role }: StudentProfileProps) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    return (
        <DropdownMenu open={isOpen} onOpenChange={setOpen}>
            <DropdownMenuTrigger asChild>
                <div className="flex flex-row-reverse items-center space-x-4 cursor-pointer">
                    {isOpen ? (
                        <ChevronUp className="w-5 h-5 text-gray-400" />
                    ) : (
                        <ChevronDown className="w-5 h-5 text-gray-400" />
                    )}
                    <div className="text-right">
                        <div className="font-bold">
                            {user.first_name} {user.last_name}
                        </div>
                        <div className="text-sm text-gray-400">
                            {user.email}
                        </div>
                    </div>
                    <div className="relative">
                        <img
                            src={
                                user.avatar_url ||
                                `https://avatar.iran.liara.run/public/${
                                    user.gender == "female" ? "girl" : "boy"
                                }`
                            }
                            alt="Profile"
                            className="w-12 h-12 rounded-full"
                        />
                    </div>
                    {/* <div className="relative">
                <Bell className="w-6 h-6 text-gray-500 fill-current" />
                {hasUnreadNotifications && (
                    <span className="absolute top-0 right-0 block h-2.5 w-2.5 bg-red-500 rounded-full ring-2 ring-white"></span>
                )}
            </div> */}
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
                <DropdownMenuGroup>
                    <Link
                        href={
                            role === "admin"
                                ? route("admin.account.edit")
                                : route("profile.edit")
                        }
                        className="cursor-pointer"
                    >
                        <DropdownMenuItem className="gap-2 cursor-pointer">
                            <User2 className="w-4 h-4" />
                            {t("app.profile")}
                        </DropdownMenuItem>
                    </Link>
                    <Link
                        href="/logout"
                        method="post"
                        className="cursor-pointer"
                    >
                        <DropdownMenuItem className="gap-2 cursor-pointer">
                            <LogOut className="w-4 h-4" />
                            {t("app.logout")}
                        </DropdownMenuItem>
                    </Link>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    );
    return (
        <Link href="/profile">
            <div className="flex flex-row-reverse items-center space-x-4">
                {/* <ChevronDown className="w-5 h-5 text-gray-400" /> */}
                <div className="text-right">
                    <div className="font-bold">{user.first_name}</div>
                    <div className="text-sm text-gray-400">{user.email}</div>
                </div>
                <div className="relative">
                    <img
                        src={
                            user.avatar_url ||
                            `https://avatar.iran.liara.run/public/${
                                user.gender == "female" ? "girl" : "boy"
                            }`
                        }
                        alt="Profile"
                        className="w-12 h-12 rounded-full"
                    />
                </div>
                {/* <div className="relative">
                <Bell className="w-6 h-6 text-gray-500 fill-current" />
                {hasUnreadNotifications && (
                    <span className="absolute top-0 right-0 block h-2.5 w-2.5 bg-red-500 rounded-full ring-2 ring-white"></span>
                )}
            </div> */}
            </div>
        </Link>
    );
};

export default StudentProfile;
