import { useState, useRef, useEffect } from "react";
import {
    Sheet,
    SheetClose,
    SheetContent,
    SheetTrigger,
} from "@/Components/ui/Sheet";
import { Button } from "@/Components/ui/Button";
import { useTranslation } from "react-i18next";
import { Link, usePage } from "@inertiajs/react";
import SmallLogo from "@/Components/ui/SmallLogo";
import MenuIcon from "@/Components/icons/MenuIcon";
import NavbarLink from "@/Components/ui/NavLink";
import {
    PublicLinksTranslation,
    PrivateLinksTranslation,
    publicLinks,
    adminLinks,
} from "@/lib/helpers/nav-links";
import { cn } from "@/lib/utils";
import { PageProps } from "@/types";
import StudentProfile from "./StudentProfile";
import { Separator } from "./ui/Separator";
import MakaremLogo from "./ui/MakaremLogo";

const mobileItems: ("courses" | "team" | "aboutUs" | "contactUs")[] = [
    "courses",
    "team",
    "aboutUs",
    "contactUs",
];

type MobileNavProps = {
    role: "admin" | "public";
};

export default function MobileNav({ role }: MobileNavProps) {
    const navLinks = role === "admin" ? adminLinks : publicLinks;
    const {
        auth: { user },
    } = usePage<PageProps>().props;
    const [open, setOpen] = useState(false);
    const sheetContentRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const handleClickOutside = (event: MouseEvent) => {
        if (
            sheetContentRef.current &&
            !sheetContentRef.current.contains(event.target as Node)
        ) {
            setOpen(false);
        }
    };

    useEffect(() => {
        if (open) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [open]);

    return (
        <>
            <Sheet open={open} onOpenChange={setOpen}>
                <div className="flex flex-row justify-between">
                    <SheetTrigger asChild>
                        <Button
                            variant="ghost"
                            size="icon"
                            className="md:hidden"
                        >
                            <MenuIcon />
                        </Button>
                    </SheetTrigger>
                    {user ? (
                        <div className="md:hidden">
                            <StudentProfile user={user} role={role} />
                        </div>
                    ) : (
                        <div className=" md:hidden">
                            <SmallLogo />
                        </div>
                    )}
                </div>

                <SheetContent ref={sheetContentRef} side="top">
                    <div className="flex flex-col items-center gap-4">
                        <div className="">
                            <Link
                                href="/"
                                className="flex flex-col items-center gap-2"
                            >
                                <SmallLogo />
                                <MakaremLogo />
                            </Link>
                        </div>
                        <Separator className="w-full" />
                        <div className="space-y-4 ">
                            <div className="flex flex-col items-center gap-3">
                                {user && role === "public" && (
                                    <div className="flex flex-col items-center gap-3 py-4">
                                        <NavbarLink
                                            href="/enrollments"
                                            className={`text-gray-500 dark:text-gray-400 hover:text-gray-700 text-xl`}
                                        >
                                            {t("links.myCourses")}
                                        </NavbarLink>

                                        {user?.roles?.find(
                                            (role) =>
                                                role.name === "admin" ||
                                                role.name === "super-admin"
                                        ) && (
                                            <NavbarLink
                                                href="/admin/dashboard"
                                                className="text-xl text-gray-500 dark:text-gray-400 hover:text-gray-700"
                                            >
                                                {t("links.adminPanel")}
                                            </NavbarLink>
                                        )}
                                    </div>
                                )}

                                {navLinks.map((link, index) => (
                                    <NavbarLink
                                        href={link.url}
                                        className={`text-xl `}
                                        key={index}
                                    >
                                        {t(link.name as PublicLinksTranslation)}
                                    </NavbarLink>
                                ))}
                            </div>
                            {user ? (
                                <br />
                            ) : (
                                <div className="flex gap-4">
                                    <Button
                                        asChild
                                        variant={"gradientBlue"}
                                        className="w-32 text-lg h-11"
                                    >
                                        <Link href={"/register"}>
                                            {" "}
                                            {t("app.register")}
                                        </Link>
                                    </Button>
                                    <Button
                                        asChild
                                        variant={"outline"}
                                        className="w-32 text-lg h-11"
                                    >
                                        <Link href={"/login"}>
                                            {" "}
                                            {t("app.login")}
                                        </Link>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </>
    );
}
