import React from "react";

function MakaremLogo() {
    return (
        <div>
            <img
                src="/LandingAssets/makarem.png"
                alt="makarem logo"
                className="w-24"
            />
        </div>
    );
}

export default MakaremLogo;
